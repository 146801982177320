import gql from "graphql-tag";

export const GET_ALL_LIVE_SESSIONS = gql`
  query GetAllLiveSessions {
    live_session(order_by: { start_at: desc }) {
      id
      title
      link
      start_at
      ends_at
      start_time
      end_time
      isRecurring
    }
  }
`;
