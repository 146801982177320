import { format } from "date-fns";
import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { usePagination, useSortBy, useTable } from "react-table";
import { GET_ALL_LIVE_SESSIONS } from "./queries";

function LiveSession({ id }) {
  const { data, loading } = useQuery(GET_ALL_LIVE_SESSIONS, {});

  const columns = useMemo(() => {
    return [
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Start At",
        accessor: "start_at",
        Cell: ({ row }) => {
          return format(new Date(row.original.start_at), "PPP");
        },
      },
      {
        Header: "Ends At",
        accessor: "ends_at",
        Cell: ({ row }) => {
          return format(new Date(row.original.ends_at), "PPP");
        },
      },
      {
        Header: "Start Time",
        accessor: "start_time",
        Cell: ({ row }) => {
          return format(new Date(row.original.start_time), "pp");
        },
      },
      {
        Header: "End Time",
        accessor: "end_time",
        Cell: ({ row }) => {
          return format(new Date(row.original.end_time), "pp");
        },
      },
      {
        Header: "Join",
        accessor: "link",
        Cell: ({ row }) => {
          return (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={row.original.link}
              className="text-blue"
            >
              Join
            </a>
          );
        },
      },
    ];
  }, [data]);

  const sessions = useMemo(() => data?.live_session ?? [], [data]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: sessions,
      initialState: { pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  if (loading)
    return <div className="w-full py-12 text-center">Loading...</div>;
  if (data?.live_session?.length > 0)
    return (
      <div className="flex flex-col gap-2 p-5">
        <div className="overflow-x-auto">
          <table
            {...getTableProps()}
            className="w-full mb-4 bg-white border-separate rounded-md shadow"
            style={{
              borderSpacing: 0,
            }}
          >
            <thead className="rounded-t-md">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index, arr) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`p-4 text-left border bg-gray-300 border-gray-400 ${
                        index === 0
                          ? "rounded-tl-md"
                          : index === arr.length - 1
                          ? "rounded-tr-md"
                          : ""
                      } whitespace-no-wrap`}
                      style={{
                        minWidth: column.minWidth,
                        width: column.width,
                        maxWidth: column.maxWidth,
                      }}
                    >
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, index) => {
                      const el =
                        typeof cell.value === "boolean"
                          ? cell.value
                            ? "True"
                            : "False"
                          : cell.render("Cell");
                      // if (index === 0) {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="p-4 border border-gray-400"
                        >
                          {el}
                        </td>
                      );
                      // } else {
                      //   if (cell.column.Header === "Role") {
                      //     return (
                      //       <td
                      //         className="text-center capitalize"
                      //         {...cell.getCellProps()}
                      //       >
                      //         {cell.value !== "" ? cell.value : "N/A"}
                      //       </td>
                      //     );
                      //   }
                      //   return (
                      //     <td className="text-center" {...cell.getCellProps()}>
                      //       {cell.value !== "" ? cell.value : "N/A"}
                      //     </td>
                      //   );
                      // }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-end gap-2">
          <button
            className="p-2 text-lg border rounded-md"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </button>{" "}
          <button
            className="p-2 text-lg border rounded-md"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"<"}
          </button>{" "}
          <button
            className="p-2 text-lg border rounded-md"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {">"}
          </button>{" "}
          <button
            className="p-2 text-lg border rounded-md"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>{" "}
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <span>
            | Go to page:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />
          </span>{" "}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50, 100, 150].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  return <div className="w-full py-12 text-center">No live session found</div>;
}

export default LiveSession;
